import { cn } from '@finn/ui-utils';

import { parseToHtml } from '~/utils/html';

type Props = {
  title: string;
  className?: string;
  titleClassName?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
};

const VerticalTabItemTitle = ({
  onClick,
  onMouseEnter,
  className,
  titleClassName,
  title,
}: Props) => {
  const getTextSection = () => {
    const boxProps = {
      ...(onClick
        ? {
            onClick: (event: any) => {
              event.preventDefault();
              if (onClick) {
                onClick();
              }
            },
          }
        : {}),
      ...(onMouseEnter
        ? {
            onMouseEnter: () => {
              if (onMouseEnter) {
                onMouseEnter();
              }
            },
          }
        : {}),
    };

    return (
      <div {...boxProps}>
        <h3
          className={cn(
            'mobile-t3-semibold md:web-t3-semibold sm:mb-6',
            titleClassName
          )}
        >
          {parseToHtml(title)}
        </h3>
      </div>
    );
  };

  return (
    <div className={cn('flex-cil flex items-center', className)}>
      {getTextSection()}
    </div>
  );
};

export default VerticalTabItemTitle;
