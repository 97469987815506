import { useCallback, useState } from 'react';

import {
  VerticalTabCosmicMetadata,
  VerticalTabItemData,
} from '~/types/UIModules';
import { parseToHtml } from '~/utils/html';

import VerticalTabItem from './VerticalTabItem';
import VerticalTabItemContent from './VerticalTabItemContent';
import VerticalTabItemTitle from './VerticalTabItemTitle';

type Props = {
  data: VerticalTabCosmicMetadata;
};

const VerticalTab = ({ data }: Props) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const getItemAt = (index: number) => {
    return data?.items?.length > index
      ? data.items[index].metadata
      : ({} as VerticalTabItemData);
  };

  const getSelectedItem = () => getItemAt(selectedItemIndex);

  const handleObjectAction = useCallback(
    (index: number) => {
      if (index !== selectedItemIndex) {
        setSelectedItemIndex(index);
      }
    },
    [selectedItemIndex]
  );

  const getTextSection = useCallback(
    (index: number) => {
      const item = getItemAt(index);
      const props = { title: item?.title, titleClassName: 'cursor-default' };
      if (index === selectedItemIndex) {
        return <VerticalTabItemTitle className="underline" {...props} />;
      } else {
        return (
          <VerticalTabItemTitle
            {...props}
            onClick={() => handleObjectAction(index)}
            onMouseEnter={() => handleObjectAction(index)}
          />
        );
      }
    },
    [getItemAt, selectedItemIndex, handleObjectAction]
  );

  return (
    <>
      <div className="container">
        {data.title && (
          <div className="mb-8 md:mb-10">
            <h2 className="mobile-t2-semibold md:web-t2-semibold">
              {parseToHtml(data.title)}
            </h2>
          </div>
        )}
        <div className="block md:hidden">
          <div className="grid gap-14 md:grid-cols-[5fr,7fr] md:gap-6">
            {data?.items?.map(({ metadata }) => (
              <VerticalTabItem key={metadata.title} item={metadata} />
            ))}
          </div>
        </div>
        <div className="hidden md:block">
          <div className="grid gap-14 md:grid-cols-[5fr,7fr] md:gap-6">
            <div>
              {data?.items?.map(({ metadata }, index: number) => (
                <div className="w-fit" key={metadata.title}>
                  {getTextSection(index)}
                </div>
              ))}
            </div>

            <VerticalTabItemContent item={getSelectedItem()} />
          </div>
        </div>
      </div>
    </>
  );
};

export default VerticalTab;
