import React from 'react';

import { VerticalTabItemData } from '~/types/UIModules';

import VerticalTabItemContent from './VerticalTabItemContent';
import VerticalTabItemTitle from './VerticalTabItemTitle';

interface IProps {
  item: VerticalTabItemData;
}

const VerticalTabItem: React.FunctionComponent<IProps> = ({ item }) => {
  return (
    <div>
      <VerticalTabItemTitle title={item?.title} />
      <VerticalTabItemContent item={item} />
    </div>
  );
};

export default VerticalTabItem;
